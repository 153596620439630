<template>
  <section class="mt-[4.5rem] pb-md px-sm max-w-[1540px] mx-auto" data-test-id="doubleBanner">
    <Stack
      direction="col"
      justify="evenly"
      gap="md"
      :breakpoints="{
        direction: { md: 'row' },
      }"
      v-if="banners.length > 0"
    >
      <div v-for="(banner, index) in banners" :key="index" class="relative flex w-full">
        <img
          v-if="banner.image"
          :src="banner.image"
          :alt="`Banner-${index}`"
          loading="lazy"
          sizes="360px"
          :class="{
            'hidden sm:block sm:object-cover sm:object-[12%] min-w-[330px] md:min-w-[180px] lg:min-h-[364px]': true,
          }"
        />
        <div
          :class="[
            'py-lg px-md sm:py-sm sm:px-xs lg:py-lg lg:px-md w-full h-[330px] lg:h-[364px] md:max-w-[394px] relative before:absolute before:hidden before:sm:block before:h-[100%] before:top-none before:right-[100%] before:border-y-[165px] lg:before:border-y-[182px] before:border-l-[87px] before:border-l-transparent',
            themeConfig[banner.theme].background,
          ]"
        >
          <p
            :class="[
              'ui-font-display -tracking-[0.05em] text-[40px] leading:[44px] md:text-4xl md:leading-[36px] lg:text-[42px] lg:leading-[46px] uppercase font-semi',
              themeConfig[banner.theme].text,
            ]"
          >
            {{ banner.title }}
          </p>
          <p
            :class="[
              'text-lg md:text-xl lg:text-lg xl:text-2xl font-semibold leading-2xl my-xs md:sm',
              themeConfig[banner.theme].description,
            ]"
          >
            {{ banner.description }}
          </p>
          <NuxtLink :to="banner.cta.href">
            <Button size="md" v-if="banner.cta.href" :variant="themeConfig[banner.theme].buttonVariant">
              {{ banner.cta.text }}
            </Button>
          </NuxtLink>
        </div>
      </div>
    </Stack>
  </section>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useDoubleBanner, themeConfig } from "../../../composables/UseContent/useDoubleBanner";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const banners = computed(() => useDoubleBanner(page.value, component.value).banners);
</script>
